html,
body,
#root {
  width: 99%;
  /* max-width: 100%; */
  height: 99%;
  /* max-height: 100%; */
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;

  /* border: 1px solid white; */
}
.App {
  height: 100%;
  width: 100%;
  max-width: 400px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: url("../public/images/background.jpeg");
  background-size: 100%, 100%;
  background-repeat: no-repeat;
  background-color: black;
  background-position: center;
}
.TopNav {
  padding: 10px;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.MainContent {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.uploadButton {
  width: 100%;
  height: 100%;
}
.uploadImage {
  flex: 1;
  /* border: 1px solid black; */
  width: 100%;
}
.mainButton {
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  box-shadow: 0px 5px 5px grey;
  border: 2px solid white;
}
.mainButton:hover {
  background-color: grey;
}

a {
  text-decoration: none;
}
.showRibbon {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.startFadeOut {
  animation: fadeOutAnimation ease 2s forwards;
}
.startFadeIn {
  animation: fadeInAnimation ease 2s forwards;
}
.libraryText {
  color: white;
  overflow: hidden;
  max-height: 40px;
}
.libraryMainDiv {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  /* border: 1px solid red; */
  padding: 5px;
}
.libraryContentDiv {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: center;
  /* border: 1px solid white; */
  border-radius: 15px;
  height: 80%;
  /* max-height: 600px; */
  overflow-y: scroll;
  padding: 5px;
}
.datText {
  font-size: 10px;
}
.libraryTitle {
  color: black;
  margin-bottom: 35px;
}
.libraryButton {
  margin-top: 10px;
  margin-bottom: 10px;
  /* border: 10px solid red; */
}
.libraryRibbonDiv {
  background-image: url("../public/images/ribbonTitle2.png");
  background-size: 100%, 100%;
  background-repeat: no-repeat;
  background-color: black;
  background-position: center;
}
